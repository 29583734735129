@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

.success,
.error {
	transition: background-color 0.25s linear;
}

.success {
	animation: fadeOut 1.5s forwards;
	background-color: hsl(120deg 90% 60% / 40%);
}

.error {
	background-color: hsl(0deg 90% 60% / 40%);
}
